import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { clearAndResetStateAction, updateProductInfo } from '../ReduxSlices/productState';
import { useLocation } from 'react-router-dom';
import { exportPDF } from '../Components/Stage/StagePDF';
import {
  findExposedSides,
  getAllowedDropCoordinates,
  getTotalBuildDims,
} from '../Components/Stage/StageFunctions';
import Axios from 'axios';

const api_address = process.env.REACT_APP_SERVER_API_ADDRESS;

const StageContext = createContext({});

export const StageProvider = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [ItemList, setItemList] = useState();
  const [buildStep, setBuildStep] = useState(1);
  const [warning, setWarning] = useState(false);
  const [configure, setConfigure] = useState();
  const [packagesData, setPackagesData] = useState();
  const [riserData, setRiserData] = useState();
  const [stageData, setStageData] = useState();
  const [packageShapes, setPackageShapes] = useState([]);
  const [packages, setPackages] = useState([]);
  const [stageBuild, setStageBuild] = useState([]);
  const [buildProducts, setBuildProducts] = useState([]);
  const [uniqueIdTracker, setUniqueIdTracker] = useState(0);
  const [hover, setHover] = useState();
  const [selected, setSelected] = useState();
  const [selectedPosition, setSelectedPosition] = useState({ top: 440, left: 1163 });
  const [dragging, setDragging] = useState();
  const [allSelected, setAllSelected] = useState(false);
  const [notConnected, setNotConnected] = useState([]);
  const [unstable, setUnstable] = useState([]);
  const [badConnections, setBadConnections] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [surfaceOptions, setSurfaceOptions] = useState();
  const [showProductList, setShowProductList] = useState(false);
  const [buildDims, setBuildDims] = useState();
  const [spaceArea, setSpaceArea] = useState();
  const [unitsInInches, setunitsInInches] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [areaDimensions, setAreaDimensions] = useState({ width: 648, length: 948 });
  const [showZoomBar, setShowZoomBar] = useState(false);
  const [zoom, setZoom] = useState(1.0);
  const [maxAreaWidth, setMaxAreaWidth] = useState(696);
  const [maxAreaLength, setMaxAreaLength] = useState(1000);
  const [savedScrollPosition, setSavedScrollPosition] = useState(0);

  const [accessoryPage, setAccessoryPage] = useState(0);
  const [cartProducts, setCartProducts] = useState();
  const [surface, setSurface] = useState('Black Carpet');
  const [stepsData, setStepsData] = useState();
  const [steps, setSteps] = useState([]);
  const [stageGuardrailData, setStageGuardrailData] = useState();
  const [riserGuardrailData, setRiserGuardrailData] = useState();
  const [sideGuardrailData, setSideGuardrailData] = useState();
  const [guardrails, setGuardrails] = useState([]);
  const [skirtingData, setSkirtingData] = useState();
  const [selectedColor, setSelectedColor] = useState();
  const [selectedStyle, setSelectedStyle] = useState();
  const [skirting, setSkirting] = useState([]);
  const [stageDolliesData, setStageDolliesData] = useState();
  const [stageDollies, setStageDollies] = useState();
  const [riserDollies, setRiserDollies] = useState();
  const [capacity, setCapacity] = useState();
  const [showOnboarding, setShowOnboarding] = useState(
    !localStorage.getItem('stage-configurator-onboarding-1') ||
      !localStorage.getItem('stage-configurator-generate-custom')
  );

  const imageRef = useRef(null);
  const Series = [
    {
      id: 0,
      title: 'Add Stages',
      name: 'Fixed Height Stages',
    },
    {
      id: 1,
      title: 'Add Risers',
      name: 'Risers',
    },
    {
      id: 2,
      title: 'Prebuilt Packages',
      name: 'PrebuiltPackage',
    },
    {
      id: 3,
      title: 'Generate Custom',
      name: 'GenerateCustom',
    },
  ];

  const createBuildOnboarding = [
    {
      title: 'Welcome to the NPS Stage Configurator',
      intro:
        '<div><div class="underline"></div><span>With this new tool from National Public Seating, <h5>you will be able to design your own configuration of stages and risers</h5>, fully customized for your space and needs. Click the button below to learn more!</span></div>',
      tooltipClass: 'welcome-tooltip',
      options: { nextLabel: 'Check It Out!' },
    },
    {
      element: '#add-stage',
      intro:
        'Add items to your build. You can choose between stages, risers, and prebuilt packages.',
      position: 'right',
    },
    {
      element: '#space-dimensions',
      intro:
        'Here you can configure the dimensions of the available space to assemble the stage. When SHOW SPACE is turned on, you will see the available space on the grid.',
      position: 'right',
    },
    {
      element: '#height-references',
      intro: 'Here you will find the height references of the stages.',
      position: 'top',
    },
    {
      element: '#view-products',
      intro: 'By clicking this button, you can check the list of products in the build.',
      position: 'left',
    },
    {
      element: `#uid-${stageBuild[0]?.uid}`, //#stage-svg  .item-options
      intro:
        'Here you will find the items in your build. Drag and drop any item to reconfigure the layout. Use the mini toolbar to make changes to the selected stage.',
      position: 'bottom',
    },
    {
      element: '.build-step-1-next .basic-button-design', //.build-step-1-next .disabled-basic-button,
      intro:
        'Once you have successfully configured your build, click COMPLETE BUILD to move on to the next step.',
      position: 'top',
    },
    {
      element: '.stage-configurator-help',
      intro: 'Click the ? at any time for guidance in building your stage.',
      position: 'left',
    },
  ];

  const generateCustomOnboarding = [
    {
      element: '.GenerateCustom',
      intro:
        'Check out our new Generate Custom feature! Easily select your room size, desired capacity, height and shape to generate a setup that best suites your needs.',
      position: 'left',
    },
  ];

  const [onboardingSteps, setOnboardingSteps] = useState(createBuildOnboarding);

  async function getStageData(product, productState, setProductState) {
    if (!productState) {
      Axios.post(`${api_address}/products/getProductData`, {
        sku: product,
      })
        .then((response) => {
          setProductState(response.data);
        })
        .catch((err) => console.error(err));
    }
  }

  function getPackagesData() {
    if (!packagesData) {
      Axios.get(`${api_address}/products/getPrebuiltStagePackages`)
        .then(async (response) => {
          setPackagesData(response.data);
          if (
            response.data.find((p) => p.items.find((i) => i.modelNum.includes('RS'))) ||
            response.data.find((p) => p.items.find((i) => i.modelNum.includes('RT')))
          ) {
            await Axios.post(`${api_address}/products/getProductData`, {
              sku: 'Risers',
            })
              .then((riserResponse) => {
                setRiserData(riserResponse.data.skuList);
                setSurfaceOptions(riserResponse.data.configOptions.finishes.Surface.Surface.values);
              })
              .catch((error) => {
                console.error('Error fetching riser data for stage package:', error);
              });
          }
          if (
            response.data.find((p) =>
              p.items.find((i) => i.modelNum.includes('S') && !i.modelNum.includes('R'))
            ) ||
            response.data.find((p) => p.items.find((i) => i.modelNum.includes('SP')))
          ) {
            await Axios.post(`${api_address}/products/getProductData`, {
              sku: 'Fixed Height Stages',
            })
              .then((stageResponse) => {
                setStageData(stageResponse.data.skuList);
                setSurfaceOptions(stageResponse.data.configOptions.finishes.Surface.Surface.values);
              })
              .catch((error) => {
                console.error('Error fetching stage data for stage package:', error);
              });
          }
          let shapesArray = [];
          response.data.forEach((p) => {
            if (
              !shapesArray.find((s) => s.shape === p.shape && s.capacityType === p.capacityType)
            ) {
              shapesArray.push({ shape: p.shape, capacityType: p.capacityType });
            }
          });
          setPackageShapes(shapesArray);
        })
        .catch((error) => {
          console.error('Error fetching stage package data:', error);
        });
    }
  }

  function handleConfigureItem(series, configOptions) {
    setSavedScrollPosition(window.scrollY);
    if (series === 'PrebuiltPackage' || series === 'GenerateCustom') {
      if (configure === series) {
        setConfigure();
      } else {
        setConfigure(series);
      }
    }
    //if click on open one
    else if (configure === series && !configOptions) {
      setConfigure();
      dispatch(clearAndResetStateAction({ stageBuilder: false, series: [] }));
    }
    //if click on closed one
    else if (!configure && !configOptions) {
      dispatch(updateProductInfo({ stageBuilder: 'newProduct', series: series }));
    }
    //if click on closed one when another is already open
    else if (configure !== series && !configOptions) {
      dispatch(clearAndResetStateAction({ stageBuilder: 'newProduct', series: series }));
    }
    //if open edit from the stage options
    else if (configOptions) {
      let optnsSelected = {};
      Object.keys(configOptions).forEach((option) => {
        if (configOptions[option].configName !== 'Surface') {
          optnsSelected[configOptions[option].configName] = configOptions[option].selectionName;
        }
      });
      //if edit an open one or if edit a closed one
      if (configure === series || !configure) {
        dispatch(
          updateProductInfo({
            stageBuilder: 'existingProduct',
            series: series,
            optionsSelected: optnsSelected,
          })
        );
      }
      //if edit a closed one when another is already open
      else if (configure !== series) {
        dispatch(
          clearAndResetStateAction({
            stageBuilder: 'existingProduct',
            series: series,
            optionsSelected: optnsSelected,
          })
        );
      }
    }
  }

  async function handleAddItem(item, productList) {
    item.productList = productList;
    setStageBuild([
      ...stageBuild,
      {
        ...item,
        uid: uniqueIdTracker,
        dimensions: getAllowedDropCoordinates(stageBuild, item),
      },
    ]);
    setSelected(uniqueIdTracker);
    setUniqueIdTracker(uniqueIdTracker + 1);
  }

  function handleDelete(selected) {
    setStageBuild((prevStageBuild) => prevStageBuild.filter((i) => i.uid !== selected));
    setSelected();
  }

  function handleDuplicate(selected) {
    const p = stageBuild.find((i) => i.uid === selected).productList;
    handleAddItem(
      ItemList.find((item) => p[0].modelNum.includes(item.modelNum)),
      p
    );
  }

  function handleSelectAll() {
    setAllSelected(!allSelected);
    setSelected();
  }

  function groupByProduct(productList) {
    let groupedByProduct = [];
    productList.forEach((item) => {
      let found = false;
      for (let p of groupedByProduct) {
        if (p.modelNum.includes(item.modelNum || item.product.modelNum)) {
          p.quantity += 1;
          found = true;
          break;
        }
      }
      if (!found) {
        console.log(item);
        groupedByProduct.push({
          ...item,
          product:
            item.product ||
            item?.productList?.find(
              (p) => p.configOptions.Surface.selectionName === 'Black Carpet'
            ),
          modelNum: item.modelNum || item.product?.modelNum,
          quantity: 1,
        });
      }
    });
    return groupedByProduct;
  }

  function resetStageStates() {
    setBuildStep(1);
    setStageBuild([]);
    setUniqueIdTracker(0);
    setSelected();
    setSelectedPosition({ top: 440, left: 1163 });
    setSpaceArea();
    setunitsInInches(false);
    setToggle(false);
    setAreaDimensions({ width: 648, length: 948 });
  }

  function setOnboardingNextStepState(nextIndex) {
    switch (nextIndex) {
      case 2:
        setConfigure();
        if (!areaDimensions.width || !areaDimensions.length)
          setAreaDimensions({ width: 516, length: 840 });
        setToggle(true);
        if (!stageBuild.length) {
          setStageBuild(
            packagesData
              .find((p) => p.id === 20)
              .items.map((i) => {
                return {
                  ...i,
                  productList: stageData?.filter((s) => s.modelNum.includes(i.modelNum)),
                };
              })
          );
        }
        return;
      case 5:
        setAllSelected(false);
        if (!selected)
          //setSelected(stageBuild.find((i) => i.uid === 361) ? 361 : 0);
          setSelected(stageBuild[0]?.uid);
        return;
    }
  }

  function resetZoom(build) {
    let buildDims = getTotalBuildDims(build);
    let length = buildDims?.right.x - buildDims?.left.x;
    let maxLength = maxAreaLength;
    let z = zoom;
    if (length > maxAreaLength) {
      do {
        z = parseFloat((z - 0.1).toFixed(1));
        maxLength = 1000 / z;
      } while (length > maxLength);
      setZoom(z);
    }
  }

  useEffect(() => {
    getPackagesData();
  }, []);

  useEffect(() => {
    if (packagesData) {
      const formattedPackagesData = [...packagesData];
      formattedPackagesData.forEach((p) => {
        p.items.map((i) => {
          i.productList =
            i.modelNum.includes('RS') || i.modelNum.includes('RT')
              ? riserData?.filter((r) => r.modelNum.includes(i.modelNum))
              : stageData?.filter((s) => s.modelNum.includes(i.modelNum));
        });
      });
      setPackages(formattedPackagesData);
    }
  }, [riserData, stageData]);

  useEffect(() => {
    window.scrollTo(0, savedScrollPosition);
  }, [location]);

  useEffect(() => {
    if (stageBuild.length) {
      setBuildDims(getTotalBuildDims(stageBuild));
    } else {
      setBuildDims();
    }
    setGuardrails(
      findExposedSides(stageBuild, true).filter(
        (side) =>
          stageBuild.find((i) => i.uid === side.itemUid)?.productList[0]?.configOptions.Height
            .value !== '8'
      )
    );
  }, [stageBuild]);

  useEffect(() => {
    // if (buildStep === 2) {
    //   if (accessoryPage > 0) {
    //     setShowOnboarding(true);
    //   }
    // }
    if (buildStep > 1) {
      setSelected();
      setAccessoryPage(0);
    } else if (buildStep === 1) {
      setCartProducts();
      setAccessoryPage(0);
      setSurface('Black Carpet');
      setSteps([]);
      setGuardrails(
        findExposedSides(stageBuild, true).filter(
          (side) =>
            stageBuild.find((i) => i.uid === side.itemUid)?.productList[0]?.configOptions.Height
              .value !== '8'
        )
      );
      //setSkirting(findExposedSides(stageBuild));
      setSkirting([]);
      setStageDollies();
      setSelectedColor();
      setSelectedStyle();
    }
    if (buildStep === 3) {
      setShowProductList();
    }
  }, [buildStep]);

  useEffect(() => {
    if (buildStep === 1) {
      if (!localStorage.getItem('stage-configurator-onboarding-1')) {
        setOnboardingSteps(createBuildOnboarding);
      } else if (!localStorage.getItem('stage-configurator-generate-custom')) {
        setConfigure('GenerateCustom');
      }
    } else if (buildStep === 2) {
      switch (accessoryPage) {
        case 0:
          setOnboardingSteps([
            {
              element: '.accessory-config',
              intro: 'Select a color the view the stage surface color.',
              position: 'right',
            },
          ]);
          break;
        case 1:
          setOnboardingSteps([
            {
              element: `#side-${
                findExposedSides(stageBuild).filter(
                  (side) =>
                    stageBuild.find((i) => i.uid === side.itemUid)?.productList[0]?.configOptions
                      .Height.value !== '8'
                )[0]?.id || 0
              }`,
              element: '#side-0',
              intro: `<div>
                <img src='https://res.cloudinary.com/da3rom333/image/upload/v1724089432/Website%20Assets/Stage%20Configurator/GIF_Steps_vibskh.gif'></img> 
                <span>Click on a grey line to add steps to that side of the stage. Click on a green line to remove the steps.<span>
                  </div>`,
              position: 'right',
            },
          ]);
          break;
        case 2:
          setOnboardingSteps([
            {
              element: `#side-${guardrails[0]?.id || 0}`,
              intro: `<div>
                <img src='https://res.cloudinary.com/da3rom333/image/upload/v1724089432/Website%20Assets/Stage%20Configurator/GIF_Guardrails_lj5grk.gif'></img> 
                <span>Click on a grey line to add guardrails to that side of the stage. Click on an orange line to remove the guardrails.<span>
                  </div>`,
              position: 'right',
            },
          ]);
          break;
        case 3:
          setOnboardingSteps([
            {
              element: '#skirting-selections',
              intro: 'Select skirting style and color.',
              position: 'right',
            },
            {
              element: `#side-${skirting[0]?.id || 0}`,
              intro: `<div>
                <img src='https://res.cloudinary.com/da3rom333/image/upload/v1724089432/Website%20Assets/Stage%20Configurator/GIF_Skirting_pf4x2s.gif'></img> 
                <span>Click on a grey line to add skirting to that side of the stage. Click on a blue line to remove the skirting.<span>
                  </div>`,
              position: 'right',
            },
          ]);
          break;
        case 4:
          setOnboardingSteps([
            {
              element: '.dollies-qty',
              intro: 'Set desired quantity of dollies.',
              position: 'right',
            },
          ]);
          break;
      }
    } else if (buildStep === 3 && accessoryPage === 0) {
      setOnboardingSteps([
        {
          element: '.stage-product-list',
          intro: 'Here you will find the list of all the products added to your build.',
          position: 'right',
        },
        {
          element: `#side-0`,
          intro:
            'Each stage side is color coded with the selected accessories. Hover your mouse over any side to see the selected accessories.',
          position: 'right',
        },
        {
          element: `#stage-pdf`,
          intro: 'Download a pdf file of your stage build.',
          position: 'top',
        },
        {
          element: `#stage-addToCart`,
          intro: 'Add your stage build to your frieght cart.',
          position: 'top',
        },
      ]);
    } else {
      setOnboardingSteps([]);
    }
    if (buildStep === 2) {
      setShowOnboarding(
        !localStorage.getItem(`stage-configurator-onboarding-${buildStep}-${accessoryPage}`)
      );
    } else {
      setShowOnboarding(!localStorage.getItem(`stage-configurator-onboarding-${buildStep}`));
    }
  }, [buildStep, accessoryPage]);

  useEffect(() => {
    console.log('configure', configure);
    if (
      configure === 'GenerateCustom' &&
      !localStorage.getItem('stage-configurator-generate-custom')
    ) {
      setShowOnboarding(true);
      setOnboardingSteps(generateCustomOnboarding);
    } else if (buildStep === 1) {
      setOnboardingSteps(createBuildOnboarding);
    }
    if (document.getElementsByClassName('introjs-helperLayer')[0]) {
      if (configure) {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '647px';
      } else {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '256px';
      }
    }
  }, [configure]);
  useEffect(() => {
    if (document.getElementsByClassName('introjs-helperLayer')[0]) {
      if (!showProductList) {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '72px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.width = '74px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.top = '925.609px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.left = '1564.09px';
      } else {
        document.getElementsByClassName('introjs-helperLayer')[0].style.height = '698px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.width = '352px';
        document.getElementsByClassName('introjs-helperLayer')[0].style.top = '19rem';
        document.getElementsByClassName('introjs-helperLayer')[0].style.left = '80.25rem';
      }
    }
  }, [showProductList]);

  useEffect(() => {
    setMaxAreaWidth(696 / zoom);
    setMaxAreaLength(1000 / zoom);
    setAreaDimensions({ ...areaDimensions });
  }, [zoom]);

  useEffect(() => {
    let totalSeatedCapacity = 0;
    let totalSeatedBandCapacity = 0;
    let totalStandingCapacity = 0;
    stageBuild.forEach((i) => {
      switch (i.capacityType) {
        case 'Seated':
          totalSeatedCapacity = totalSeatedCapacity + i.capacity;
          break;
        case 'Seated Band':
          totalSeatedBandCapacity = totalSeatedBandCapacity + i.capacity;
          break;
        case 'Standing':
          totalStandingCapacity = totalStandingCapacity + i.capacity;
          break;
      }
    });
    setCapacity({
      Seated: totalSeatedCapacity,
      SeatedBand: totalSeatedBandCapacity,
      Standing: totalStandingCapacity,
    });
  }, [stageBuild]);

  return (
    <StageContext.Provider
      value={{
        Series,
        ItemList,
        setItemList,
        buildStep,
        setBuildStep,
        warning,
        setWarning,
        configure,
        setConfigure,
        packagesData,
        setPackagesData,
        riserData,
        stageData,
        packageShapes,
        packages,
        stageBuild,
        setStageBuild,
        buildProducts,
        setBuildProducts,
        uniqueIdTracker,
        setUniqueIdTracker,
        hover,
        setHover,
        selected,
        setSelected,
        selectedPosition,
        setSelectedPosition,
        dragging,
        setDragging,
        allSelected,
        setAllSelected,
        notConnected,
        setNotConnected,
        unstable,
        setUnstable,
        badConnections,
        setBadConnections,
        openPopup,
        setOpenPopup,
        surfaceOptions,
        setSurfaceOptions,
        showProductList,
        setShowProductList,
        buildDims,
        setBuildDims,
        spaceArea,
        setSpaceArea,
        unitsInInches,
        setunitsInInches,
        toggle,
        setToggle,
        areaDimensions,
        setAreaDimensions,
        showZoomBar,
        setShowZoomBar,
        zoom,
        setZoom,
        maxAreaWidth,
        maxAreaLength,
        savedScrollPosition,
        setSavedScrollPosition,

        // accessories and cart states
        accessoryPage,
        setAccessoryPage,
        cartProducts,
        setCartProducts,
        surface,
        setSurface,
        stepsData,
        setStepsData,
        steps,
        setSteps,
        stageGuardrailData,
        setStageGuardrailData,
        riserGuardrailData,
        setRiserGuardrailData,
        sideGuardrailData,
        setSideGuardrailData,
        guardrails,
        setGuardrails,
        skirtingData,
        setSkirtingData,
        selectedColor,
        setSelectedColor,
        selectedStyle,
        setSelectedStyle,
        skirting,
        setSkirting,
        stageDolliesData,
        setStageDolliesData,
        stageDollies,
        setStageDollies,
        riserDollies,
        setRiserDollies,

        showOnboarding,
        setShowOnboarding,
        onboardingSteps,
        imageRef,
        capacity,
        // createBuildOnboarding,
        // addAccessoriesOnboarding,

        //functions
        getStageData,
        getPackagesData,
        handleConfigureItem,
        handleAddItem,
        handleDelete,
        handleDuplicate,
        handleSelectAll,
        groupByProduct,
        resetStageStates,
        exportPDF,
        setOnboardingNextStepState,
        resetZoom,
      }}
    >
      {children}
    </StageContext.Provider>
  );
};

export default StageContext;

export const useStageInfo = () => {
  return useContext(StageContext);
};
