import HeaderNav from './Header/HeaderNav';
import Header from './Header/Header';
import Footer from './Footer/footer';
import { Outlet, useNavigate } from 'react-router-dom';
import { ImArrowUp2 } from 'react-icons/im';
import { BsGrid3X3GapFill } from 'react-icons/bs';
import { FiMinusCircle, FiPlusCircle } from 'react-icons/fi';
import useAuth from '../hooks/useAuth';
import useAxiosJWT from '../hooks/useAxiosJWT';

import '../CSS/Layout.css';
import { useEffect, useState } from 'react';
import Axios from 'axios';
import { useSelector } from 'react-redux';

const contact =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653574/Website%20Assets/SVGs/Contact_Us_xe7p7t.svg';
const dealer =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653575/Website%20Assets/SVGs/Dealer_Portal_zo9epu.svg';
const track =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653565/Website%20Assets/SVGs/Track_Your_Order_cjd5zm.svg';
const guide =
  'https://res.cloudinary.com/da3rom333/image/upload/v1718993486/Button_Website_Guide_1_bzvyoj.svg';
const Layout = () => {
  const { auth } = useAuth();
  const axiosJWT = useAxiosJWT();
  const [open, setOpen] = useState(false);
  const [bannerNotifications, setBannerNotifications] = useState();
  const [responseData, setResponseData] = useState();
  const [testBanner, setTestBanner] = useState('');
  const navigate = useNavigate();

  const mobileScreen = useSelector((state) => state.isMobile.value);

  function getData() {
    axiosJWT
      .get(`content/getBannerNotifications`)
      .then((response) => {
        setResponseData(response.data);
      })
      .catch(() => {
        // console.log(err);
      });
  }

  useEffect(() => {
    getData();
    checkLocation();
  }, []);
  // useEffect(() => {
  //   filterBannerNotifications(responseData);
  // }, [window?.location.pathname]);

  const testLocations = [
    'localhost',
    '192.168.0.185',
    '192.168.1.182',
    '192.168.0.194',
    '192.168.1.129',
    'test.nationalpublicseating.com',
  ];

  const checkLocation = () => {
    // console.log(window.location.hostname, 51);
    if (testLocations.includes(window.location.hostname)) {
      setTestBanner(<div>THIS IS A TEST SITE!</div>);
    } else {
      setTestBanner();
    }
  };

  function filterBannerNotifications() {
    const currentDate = new Date();
    setBannerNotifications(
      responseData
        ?.filter((obj) => {
          // console.log(obj.Path, window.location.pathname);
          return (
            (obj['On/Off'] === 1 ||
              (obj.Active === 1 &&
                obj['Start Date'] &&
                obj['End Date'] &&
                new Date(obj['Start Date']) <= currentDate &&
                currentDate <= new Date(obj['End Date']))) &&
            (obj.Path ? obj.Path === window.location.pathname : true)
          );
        })
        ?.filter((i) => i.type === 'banner' || i.type === 'maintenance')
        ?.filter((i) => (!auth?.rep ? i.require_auth === 0 : true))
    );
  }

  useEffect(() => {
    filterBannerNotifications();
  }, [responseData, window.location.pathname]);

  return (
    <div className='content'>
      {
        // window.location.pathname === "/cart" ||
        testBanner || bannerNotifications?.filter((i) => i.type !== 'maintenance').length ? (
          <div
            className='notification-banner'
            style={{
              background: 'red',
              // marginLeft: "-2rem",
              width: mobileScreen.isMobile ? '1440px' : '100%',
              color: 'white',
              padding: '.5rem',
              textAlign: 'center',
              // paddingRight: "2rem",
              fontSize: mobileScreen.isMobile ? '50px' : 'initial',
              boxSizing: 'border-box',
            }}
          >
            {' '}
            {bannerNotifications
              ?.filter((i) => i.type !== 'maintenance')
              ?.map((i, index) => (
                <div key={index}>{i.Text}</div>
              ))}
            {testBanner}
            {/* {window.location.pathname === "/cart" &&
            "Our freight cart is currently down; we apologize for this inconvenience. If you need freight quote, please email freightquotes@nationalpublicseating.com or reach out to your account manager for assistance."} */}
          </div>
        ) : (
          ''
        )
      }
      <div className='grid'>
        <div className='grid-paddding-outer'></div>
      </div>
      <Header />
      {bannerNotifications?.find((i) => i.type === 'maintenance') &&
      bannerNotifications?.find((i) => i.type === 'maintenance')['On/Off'] === 1 &&
      !auth?.roles?.includes(1117) ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
          className='inner-padding'
        >
          <img
            style={{ width: '80rem' }}
            src='https://res.cloudinary.com/da3rom333/image/upload/v1715953465/Website%20Assets/Banners/UNDER_MAINTENANCE_aly0oc.png'
          ></img>
        </div>
      ) : (
        <>
          <HeaderNav />

          <Outlet />

          <div className='arrowContact'>
            <div
              className='arrow'
              onClick={() => {
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <ImArrowUp2 />
            </div>
            <div
              className='menu'
              onClick={() => {
                setOpen(!open);
              }}
            >
              {open ? <FiMinusCircle className='open' /> : <FiPlusCircle className='open' />}{' '}
              <BsGrid3X3GapFill className='square' />
            </div>
            {open ? (
              <div className='menu-open'>
                <div
                  onClick={() => {
                    setOpen(false);
                    window.open(
                      'https://res.cloudinary.com/da3rom333/image/upload/v1718051784/Product%20Assets/Literature/Misc%20Documents/Website_Guide_vlito9.pdf'
                    );
                  }}
                >
                  <img
                    src={guide}
                    style={{ transform: 'scale(1.08)', marginLeft: '-2px' }}
                  ></img>
                </div>
                <div
                  onClick={() => {
                    setOpen(false);
                    navigate('/contact?category=contact-us');
                  }}
                >
                  <img src={contact}></img>
                </div>
                <div
                  onClick={() => {
                    setOpen(false);
                    navigate('/track-order');
                  }}
                >
                  <img src={track}></img>
                </div>
                <div
                  onClick={() => {
                    setOpen(false);
                    navigate('/dealer-portal');
                  }}
                >
                  <img src={dealer}></img>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
};

export default Layout;
