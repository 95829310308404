import { useEffect, useState } from 'react';
import ConfigCube from './ConfigCube';
import Sku from './Sku';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import '../../CSS/ProductAddToCart.css';
import FormatImageLink from '../../Components/Shared/FormatImageLink';
import AddOnBar from './AddOnBar';
import { Scrollbar } from 'react-scrollbars-custom';
import LaminatesInfoHover from './LaminatesInfoHover';
import { useDispatch, useSelector } from 'react-redux';
import { useCart } from '../../context/CartContext';
import ProductCart from '../../Pages/Products/ProductCart';

const Configurations = () => {
  const {
    configTypes: configOptions,
    configSelected,
    optionsSelected,
    product,
    type,
    stageBuilder,
  } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const screenType = useSelector((state) => state.isMobile.value);
  const { addToCart } = useCart();
  const [remainder, setRemainder] = useState([]);
  const [infoHover, setInfoHover] = useState(false);
  const [showMobileConfigs, setShowMobileConfigs] = useState(false);
  const [configDivHeight, setConfigDivHeight] = useState(50);
  const presetOptions = [];
  const flatArray = [];
  const decodeAndFormat = FormatImageLink();
  useEffect(() => {
    const productDescDiv = document.getElementById('productDescDiv');
    const height = productDescDiv.clientHeight;
    setConfigDivHeight(stageBuilder ? 486 : 600 - height); //with generate custom
    //setConfigDivHeight(stageBuilder ? 588 : 600 - height);
  }, [product?.productInfo?.ShortDescription]);

  useEffect(() => {
    if (Object.keys(configOptions).length % 4 !== 0) {
      var array = [];
      var count = 4 - (Object.keys(configOptions).length % 4);
      for (let i = 0; i < count; i++) {
        array.push(i);
      }
      setRemainder(array);
    }
  }, []);

  return (
    <div
      style={{
        height: configDivHeight,
      }}
      className='config-div'
    >
      {infoHover && <LaminatesInfoHover />}
      <Sku
        presetOptions={presetOptions}
        fullConfigOptions={configOptions}
      />

      {screenType.isMobile && (
        <button
          className={`mobile-config-dropdown-btn ${showMobileConfigs ? 'no-border' : ''}`}
          onClick={() => setShowMobileConfigs(!showMobileConfigs)}
        >
          CONFIGURE YOUR {type}
          {showMobileConfigs ? (
            <BsChevronUp className='down-arrow' />
          ) : (
            <BsChevronDown className='down-arrow' />
          )}
        </button>
      )}
      {!screenType.isMobile || showMobileConfigs ? (
        <div className='config-div-inner'>
          <Scrollbar
            id='scrollbar'
            style={{
              width: 591,
              height: configDivHeight - 55,
            }}
          >
            {Object.keys(configOptions).map((optionKey) => {
              if (
                optionKey.toLowerCase() !== 'addons' &&
                optionKey.toLowerCase() !== 'accessories'
              ) {
                const option = configOptions[optionKey];
                const optionComponents = [];

                for (const key in option) {
                  if (Object.values(option[key]).some((i) => i.values.length > 1)) {
                    flatArray.push(option[key]);
                  }
                  Object.values(option[key]).forEach((i) => {
                    if (i.values.length <= 1) {
                      presetOptions.push(i);
                    }
                  });
                }

                optionComponents.push(
                  Object.keys(option).map((key) => {
                    const subcategory = option[key];

                    return (
                      product?.configOptions &&
                      Object?.keys(product?.configOptions)?.some((value) =>
                        Object.keys(subcategory).includes(value)
                      ) &&
                      Object.values(subcategory).some((i) => i.values.length > 1) && (
                        <div
                          id={key}
                          key={key}
                        >
                          {stageBuilder && key === 'Surface' ? (
                            ''
                          ) : (
                            <ConfigCube
                              title={key}
                              key={key}
                              option={subcategory}
                              configSelected={
                                Object.keys(product?.configOptions).length > 1
                                  ? configSelected
                                  : subcategory
                              }
                              flatArray={flatArray}
                              setInfoHover={setInfoHover}
                            />
                          )}
                        </div>
                      )
                    );
                  })
                );

                return optionComponents;
              }
              return null;
            })}

            {Object.keys(configOptions).map((optionKey) => {
              if (optionKey.toLowerCase() === 'addons') {
                const addOns = configOptions[optionKey];
                if (Object.keys(addOns).length > 0) {
                  return (
                    <AddOnBar
                      type='addOn'
                      addOnTypes={addOns}
                      key='addOns'
                    />
                  );
                }
              } else if (optionKey.toLowerCase() === 'accessories') {
                const accessories = configOptions[optionKey];
                if (Object.keys(accessories).length > 0) {
                  return (
                    <AddOnBar
                      type='accessory'
                      key='addOns'
                    />
                  );
                }
              }
              return null;
            })}

            <ProductCart
              addToCart={addToCart}
              page={'configurations'}
            />
          </Scrollbar>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default Configurations;
