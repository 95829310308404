import React from 'react';
import { FiMinus, FiPlus } from 'react-icons/fi';
import PrebuiltPackages from './PrebuiltPackages';
import GenerateCustomStage from './GenerateCustomStage';
import ProductsPage from '../../Pages/Products/productsPageNew';
import LoadingIcon from '../Shared/LoadingIcon';
import { useSelector } from 'react-redux';
import '../../CSS/StageSidebar.css';
import SidebarHeader from './SidebarHeader';
import AddAccessories from './AddAccessories';
import { useStageInfo } from '../../context/StageProvider';
import AddToCart from './AddToCart';
import DimensionsReference from './DimensionsReference';
import BasicButton from '../NPSportal/basicButton';

function StageSidebar() {
  const {
    Series,
    buildStep,
    setBuildStep,
    configure,
    handleConfigureItem,
    stageBuild,
    warning,
    setWarning,
  } = useStageInfo();
  const { product, stageBuilder } = useSelector((state) => state.productState.value);

  return (
    <div className='stage-category-sidebar'>
      <SidebarHeader />
      {warning ? (
        <div className='build-warning'>
          <h4>Going back to Create Build will remove your accessory selections! </h4>
          <span className='basic-flex'>
            <BasicButton
              onClick={() => {
                setWarning(false);
              }}
              text={'Cancel'}
              type={'basic-white-button'}
            />
            <BasicButton
              onClick={() => {
                setWarning(false);
                setBuildStep(1);
              }}
              text={'Continue'}
              type={'white-bordered-button'}
            />
          </span>
        </div>
      ) : buildStep === 1 ? (
        <>
          <div
            className='stage-category-sidebar'
            id='add-stage'
          >
            {Series.map((series, index) => {
              return (
                <div
                  key={index}
                  className={`${series.name} ${
                    configure === series.name ? ' open-category-wrapper' : ''
                  }`}
                >
                  <div
                    key={series.id}
                    className={`stage-category ${
                      configure === series.name ? 'stage-category-open' : ''
                    }`}
                    onClick={() => handleConfigureItem(series.name)}
                  >
                    {series.title}
                    {configure === series.name ? <FiMinus /> : <FiPlus />}
                  </div>
                  {configure === series.name && (
                    <div className='category-dropdown-wrapper'>
                      {configure === 'PrebuiltPackage' ? (
                        <PrebuiltPackages />
                      ) : configure === 'GenerateCustom' ? (
                        <GenerateCustomStage />
                      ) : (
                        <>
                          <div className={`scaled-product-page ${product ? '' : 'hide'}`}>
                            {stageBuilder && <ProductsPage />}
                          </div>
                          {!product && (
                            <div className='loading-stage'>
                              <LoadingIcon />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div
            className='build-step-1-next'
            style={{ display: configure ? 'none' : 'flex' }}
          >
            <div>
              <BasicButton
                onClick={() => {
                  setBuildStep(2);
                }}
                text={'Complete Build'}
                type={'white-bordered-button'}
                disabled={!stageBuild.length}
              />
            </div>
          </div>
          <DimensionsReference />
        </>
      ) : buildStep === 2 ? (
        <div>
          <AddAccessories />
        </div>
      ) : (
        buildStep === 3 && (
          <div>
            <AddToCart />
          </div>
        )
      )}
    </div>
  );
}

export default StageSidebar;
